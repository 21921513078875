import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyBwWgxqSJDNd2qpHQUFhDQlFoxpNUzTrlg",
    authDomain: "siege-up.firebaseapp.com",
    databaseURL: "https://siege-up.firebaseio.com",
    projectId: "siege-up",
    storageBucket: "siege-up.appspot.com",
    messagingSenderId: "528981842299",
    appId: "1:528981842299:web:a7984212a3215451a205de",
    measurementId: "G-TQE9BFEZ6R"
  };

  const firebaseApp = initializeApp(firebaseConfig);
  const auth = getAuth(firebaseApp);

  export { auth };