import { auth } from "~/plugins/firebase.js";

export const state = () => ({
    userData: null,
});

export const mutations = {
    setUserData(state, userData) {
        state.userData = userData;
    },
};

export const actions = {
    async requestUserData({ commit }) {
        try {
            const idToken = await auth.currentUser.getIdToken(true);
            const response = await this.$axios.get("player", {
                headers: {
                    Authorization: idToken,
                },
            });
            const userData = response.data;
            console.log("User data:", userData);
            commit('setUserData', userData);
        }
        catch (error)
        {
            console.error("Error requesting user data:", error.message);
            throw error;
        }
    },
};
